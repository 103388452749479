import React, { useRef, useState, useEffect } from "react";

import loadernew from "../images/loadernew.gif";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import ReactPaginate from "react-paginate";

function Contact() {
  const [isLoading, setIsLoading] = useState(true);

  const vidRef = useRef();
  const [myform, setForm] = useState([]);
  const loadimg = () => {
    setIsLoading(true);
    axios.post("https://api.sribalajisolarenergies.com/v1/solarapi/web/enquiry/getallenquiries").then((res) => {
      console.log(res);
      setForm(res.data.enquiries);
      setIsLoading(false);
    });
  };
  useEffect(() => {
    loadimg();
  }, []);

  const [listPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  const pagesVisited = pageNumber * listPerPage;
  const lists = myform.slice(pagesVisited, pagesVisited + listPerPage);
  const pageCount = Math.ceil(myform.length / listPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div
            style={{ zIndex: "9999999999999", height: "420px" }}
            className="text-center mt-3 mb-5"
          >
            <img
              src={loadernew}
              height="140px"
              style={{ marginTop: "250px" }}
            ></img>
          </div>
        </>
      ) : (
        <>
          <Header />
          <div className="page-header parallaxie">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-header-box">
                    <h1 className="text-anime">Leads</h1>
                    <nav className="wow fadeInUp" data-wow-delay="0.25s">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="/">Home</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Leads
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-information mb-5 py-5">
            <div className="container">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Sl No</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Subject</th>
                    <th>Message</th>
                  </tr>
                </thead>
                <tbody>
                {lists.map((data, index) => (
                                <tr key={index}>
                    <th>{(pageNumber - 1) * 10 + index + 11}</th>
                    <td>{data.name}</td>
                    <td>{data.email}</td>
                    <td>{data.phone}</td>
                    <td>{data.subject}</td>
                    <td>{data.description}</td>
                  </tr>
                   ))}
                </tbody>
              </table>
              <div className="mt-3" style={{ float: "right" }}>
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"pagination"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"disabled"}
                  activeClassName={"active"}
                  total={lists.length}
                />
              </div>
            </div>
          </div>

          <Toaster />
          {/* <Footer /> */}
        </>
      )}
    </div>
  );
}

export default Contact;
