import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
// import Button from "react-bootstrap/Button";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
function Header() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [open, setopen] = useState(false);
  const show1 = () => {
    setopen(!open);
  };
  const [open1, setopen1] = useState(false);
  const [open2, setopen2] = useState(false);
  const [open3, setopen3] = useState(false);
  const show2 = () => {
    setopen1(!open1);
  };
  const show4 = () => {
    setopen3(!open3);
  };
  const show3 = () => {
    setopen2(!open2);
  };

  const [form, setform] = useState({
    name: "",
    phone: "",
    email: "",
    subject: "",
    description: "",
  });

  const handlechange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const addUsers = (e) => {
    e.preventDefault();
    var bodydata = {
      name: form.name,
      phone: form.phone,
      email: form.email,
      subject: form.subject,
      description: form.description,
    };
    axios.post("https://api.sribalajisolarenergies.com/v1/solarapi/web/enquiry/addenquiry", bodydata, {}).then((res) => {
      toast.success(
        "Added Successfully. Your data received, Our team will get back to you soon!"
      );
      // userdata();
      setform({
        name: "",
        phone: "",
        email: "",
        subject: "",
        description: "",
      })
      handleClose();
      // navigate('/thankyou')
    });
  };

  return (
    <div>
      <div id="magic-cursor">
        <div id="ball" />
      </div>
      <div className="topbar wow fadeInUp">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="topbar-contact-info">
                <ul>
                  <li>
                    <a href="mailto: sbse4444@gmail.com">
                      <i className="fa-solid fa-envelope" />
                      sbse4444@gmail.com
                    </a>
                  </li>
                  <li>
                    <a href="tel:+91 951 595 49 23">
                      <i className="fa-solid fa-phone" />
                      +91 903 204 1774
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="header-social-links">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/sribalajisolarenergies"
                      target="_blank"
                    >
                      <i className="fa-brands fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/SriBalajiSolar_"
                      target="_blank"
                    >
                      <i className="fa-brands fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/sri-balaji-solar-energies-45b841304/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-linkedin-in" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/sri_balaji_solar_energies/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/@sribalajisolarenergies6619/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-youtube" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <header
        className="main-header"
        style={{ height: "50", marginTop: "8px", marginBottom: "8px" }}
      >
        <div className="header-sticky">
          <nav className="navbar navbar-expand-lg">
            <div className="container">
              <a className="navbar-brand" href="/">
                <img src="images/LOGO.png" alt="Logo" width={220} height={50} />
              </a>

              <div className="collapse navbar-collapse main-menu">
                <ul class="navbar-nav mr-auto" id="menu">
                  <li class="nav-item">
                    <NavLink to="/" className="nav-link ">
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/about" className="nav-link">
                      About us
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="#" className="nav-link">
                      Products
                    </NavLink>
                    <ul>
                      <li className="nav-item">
                        <NavLink className="nav-link">Solar Rooftops</NavLink>
                        <ul>
                          <li className="nav-item">
                            <NavLink
                              to="/industrial-rooftops"
                              className="nav-link"
                            >
                              Industrial Solar Rooftops{" "}
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              to="/commercial-rooftops "
                              className="nav-link"
                            >
                              Commercial Solar Rooftops{" "}
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              to="/Residential-Rooftops"
                              className="nav-link"
                            >
                              Residential Solar Rooftops{" "}
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              to="/On-Grid-Connected-Solar-Pvsystems"
                              className="nav-link"
                            >
                              ON-GRID CONNECTED SOLAR PV SYSTEMS
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              to="/Off-Grid-Connected-Solar-Pvsystems"
                              className="nav-link"
                            >
                              OFF-GRID CONNECTED SOLAR PV SYSTEMS{" "}
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/solar-waterheater" className="nav-link">
                          Solar Water Heater
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/SolarWaterPumpingSystem"
                          className="nav-link"
                        >
                          Solar Water Pumping System
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/LedStreetLights" className="nav-link">
                          Led Street Lights
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/SolarFencingSystem" className="nav-link">
                          Solar Fencing System
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/SolarStreetLights" className="nav-link">
                          Solar Street Lights
                        </NavLink>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/testimonials" className="nav-link">
                      Testimonials
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink className="nav-link">Gallery</NavLink>
                    <ul>
                      <li className="nav-item">
                        <NavLink to="/Projects" className="nav-link">
                          Projects
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/awards" className="nav-link">
                          Awards
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/Clients" className="nav-link">
                      Clients
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/Contact" className="nav-link">
                      Contact
                    </NavLink>
                  </li>
                  <li
                    className="nav-item highlighted-menu"
                    onClick={handleShow}
                  >
                    <a className="nav-link">Book Now</a>
                  </li>
                </ul>
              </div>

              <div class="navbar-toggle">
                <a
                  href="#"
                  aria-haspopup="true"
                  role="button"
                  tabindex="0"
                  onClick={() => {
                    show1();
                  }}
                  className={
                    open == true
                      ? "slicknav_btn slicknav_open"
                      : "slicknav_btn slicknav_collapsed"
                  }
                  style={{ outline: "none" }}
                >
                  <span class="slicknav_menutxt"></span>
                  <span class="slicknav_icon slicknav_no-text">
                    <span class="slicknav_icon-bar"></span>
                    <span class="slicknav_icon-bar"></span>
                    <span class="slicknav_icon-bar"></span>
                  </span>
                </a>
              </div>
            </div>
          </nav>
          <div class="responsive-menu">
            <div class="slicknav_menu">
              <ul
                aria-hidden={open ? "false" : "true"}
                role="menu"
                className={
                  open ? "slicknav_nav " : "slicknav_nav slicknav_hidden"
                }
                style={{ display: open ? " " : "none" }}
              >
                <li class="nav-item">
                  <NavLink to="/" className="nav-link ">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/about" className="nav-link">
                    About us
                  </NavLink>
                </li>
                <li
                  className={
                    open1
                      ? "nav-item submenu slicknav_parent slicknav_open"
                      : "nav-item submenu slicknav_parent slicknav_collapsed"
                  }
                  onClick={() => {
                    show2();
                  }}
                >
                  <NavLink to="#" className="nav-link">
                    Products
                  </NavLink>
                  <ul
                    role="menu"
                    aria-hidden={open1 ? "false" : "true"}
                    className={open1 ? " " : "slicknav_hidden "}
                    style={{ display: open1 ? " " : "none" }}
                  >
                    <li className="nav-item">
                      <NavLink className="nav-link">Solar Rooftops</NavLink>
                      <ul>
                        <li className="nav-item">
                          <NavLink
                            to="/industrial-rooftops"
                            className="nav-link"
                          >
                            Industrial Solar Rooftops{" "}
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink
                            to="/commercial-rooftops "
                            className="nav-link"
                          >
                            Commercial Solar Rooftops{" "}
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink
                            to="/Residential-Rooftops"
                            className="nav-link"
                          >
                            Residential Solar Rooftops{" "}
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/solar-waterheater" className="nav-link">
                        Solar Water Heater
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/SolarWaterPumpingSystem"
                        className="nav-link"
                      >
                        Solar Water Pumping System
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/LedStreetLights" className="nav-link">
                        Led Street Lights
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/SolarFencingSystem" className="nav-link">
                        Solar Fencing System
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/SolarStreetLights" className="nav-link">
                        Solar Street Lights
                      </NavLink>
                    </li>
                  </ul>
                </li>

                {/* <li className={open2 ? "nav-item submenu slicknav_parent slicknav_open" : "nav-item submenu slicknav_parent slicknav_collapsed"} onClick={() => { show3() }}><NavLink className="nav-link">Client Stories</NavLink> */}

                <li className="nav-item">
                  <NavLink to="/testimonials" className="nav-link">
                    Testimonials
                  </NavLink>
                </li>
                {/* </li> */}
                <li
                  className={
                    open3
                      ? "nav-item submenu slicknav_parent slicknav_open"
                      : "nav-item submenu slicknav_parent slicknav_collapsed"
                  }
                  onClick={() => {
                    show4();
                  }}
                >
                  <NavLink className="nav-link">Gallery</NavLink>
                  <ul
                    role="menu"
                    className={open3 ? "" : "slicknav_hidden"}
                    aria-hidden={open3 ? "false" : "true"}
                    style={{ display: open3 ? " " : "none" }}
                  >
                    <li className="nav-item">
                      <NavLink to="/Projects" className="nav-link">
                        Projects
                      </NavLink>
                    </li>
                    {/* <li className="nav-item"><NavLink to="/awards" className="nav-link">Awards</NavLink></li> */}
                  </ul>
                </li>
                {/* <li className="nav-item"><NavLink to="/carrier" className="nav-link">Careers</NavLink></li> */}
                <li className="nav-item">
                  <NavLink to="/Contact" className="nav-link">
                    Contact
                  </NavLink>
                </li>
                <li className="nav-item highlighted-menu" onClick={handleShow}>
                  <a className="nav-link">Book Now</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Get In Touch With Us!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            id="contactForm"
            // action="#"
            // method="POST"
            data-toggle="validator"
            onSubmit={(e) => {
              addUsers(e);
            }}
          >
            <div className="row">
              <div className="form-group col-md-6 mb-4">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  id="name"
                  placeholder="Name"
                  required
                  value={form.name}
                  onChange={(e) => {
                    handlechange(e);
                  }}
                />
                <div className="help-block with-errors" />
              </div>
              <div className="form-group col-md-6 mb-4">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  id="email"
                  placeholder="Email"
                  required
                  value={form.email}
                  onChange={(e) => {
                    handlechange(e);
                  }}
                />
                <div className="help-block with-errors" />
              </div>
              <div className="form-group col-md-6 mb-4">
                <input
                  type="text"
                  minlength="0"
                  maxlength="10"
                  pattern="\d{10}"
                  name="phone"
                  className="form-control"
                  id="phone"
                  placeholder="Phone"
                  required
                  value={form.phone}
                  onChange={(e) => {
                    handlechange(e);
                  }}
                />
                <div className="help-block with-errors" />
              </div>
              <div className="form-group col-md-6 mb-4">
                <input
                  type="text"
                  name="subject"
                  className="form-control"
                  id="subject"
                  placeholder="Subject"
                  required
                  value={form.subject}
                  onChange={(e) => {
                    handlechange(e);
                  }}
                />
                <div className="help-block with-errors" />
              </div>
              <div className="form-group col-md-12 mb-4">
                <textarea
                  name="description"
                  className="form-control"
                  id="msg"
                  rows={4}
                  placeholder="Write a Message"
                  required
                  value={form.description}
                  onChange={(e) => {
                    handlechange(e);
                  }}
                />
                <div className="help-block with-errors" />
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn-default"
                  // onClick={handleClose}
                >
                  Submit Now
                </button>
                <div id="msgSubmit" className="h3 text-left hidden" />
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/* <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="mt-5"
      >

        <div onClick={handleClose}>
          <i class="fa fa-times modalstyle " aria-hidden="true" ></i>
        </div>
        <div className="row login-page ">
          <div className="col-md-5 mobileslider cross">
            <div className=' login-side-des'>
              <div className="container-fluid">
                <div className="login-side-block">
                <a className="navbar-brand" href="/">
                <img src="images/LOGO.png" alt="Logo" width={220} height={70} />
              </a>
                  <div className="login-reviews">
                    <div className="review-details-content">
                      <div className="owl-carousel review_details" id="review_details-2">
                        <Slider {...settings1} >
                          <div className="item">
                            <p>"Our Dr.Narayana group of institutions were very happy with digital raiz solutions.. very satisfied with their work and response..."</p>
                            <h5>Sravani Reddy</h5>
                            <h6>Vice Princile of Dr.Narayana group of institutions</h6>
                          </div>
                         
                          <div className="item">
                            <p>" Digital Raiz team developed a mobile app for me. They are the best in app development more over they save my money and time. I suggest Digital Raiz for mobile app development."</p>
                            <h5>Ganesh</h5>
                            <h6>Chairmen of TaxiGk</h6>
                          </div>
                         
                          <div className="item">
                            <p>"Best website design company in Hyderabad...I am on of the client in Digital Raiz creative solutions, its a good customer services. our project will done at limited. Thankyou digital Raiz creative solutions for your support."</p>
                            <h5>Abdullah</h5>
                            <h6>CEO of Iotroncs</h6>
                          </div>
                     
                          <div className="item">
                            <p>"Digital Raiz made a website and mobile app for us. Product is excellent and their support is outstanding. Thanks to staff and management."</p>
                            <h5> Bhupesh </h5>
                            <h6>Md of SLN Services</h6>
                          </div>
                          
                          <div className="item">
                            <p>"Very efficient and reliable team. Definitely recommend Digital Raiz Creative Solutions for those who are looking for digital marketing services within budget and on time. Excellent client services and support provided. Thanks Team!"</p>
                            <h5>Bala Koteshwara Rao</h5>
                            <h6>MD of Thrilokmart</h6>
                          </div>

                          <div className="item">
                            <p>"They procure a Very Good team in digital marketing and web development. I recommend my customers to join hands with Digital Raiz"</p>
                            <h5>Sai Kiran</h5>
                            <h6>MD of Vedithtech</h6>
                          </div>
                          <div className="item">
                            <p>"Good quality best team members in time pra project submission.......Thank You DigitalRaiz."</p>
                            <h5>Manikanta</h5>
                            <h6>Chairmen of SMV Chemical</h6>
                          </div>
                          <div className="item">
                            <p>"DigitalRaiz developed my app name SIFALO, within time at a low cost. I suggested this company for mobile applications. "</p>
                            <h5>Khalil Ahmed</h5>
                            <h6>Somalia VP of Sifalo</h6>
                          </div>
                          <div className="item">
                            <p>"We were truly impressed! The way digital Raiz developed my mobile application in the name of EXMOB, with less cost, on-time delivery, and finally, the way of conducting meetings and gathering the requirement was excellent. Thank you so much, digital raiz team."</p>
                            <h5>Nawaz</h5>
                            <h6>Chairmen of EXMOB</h6>
                          </div>
                          <div className="item">
                            <p>"One of the best digital marketing agencies to generate potential leads through google ads is DigitalRaiz. Good Job Done."</p>
                            <h5>Nawaf</h5>
                            <h6>UAE</h6>
                          </div>
                          <div className="item">
                            <p>"We have hired them for our Mobile application development work,
                              They are productive and punctual in work they have completed our new mobile application very quickly. I truly recommend the digital raiz for abroad clients."</p>
                            <h5>Branty Bruno</h5>
                            <h6>France CEO of Decohindi</h6>
                          </div>
                        </Slider>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="col-md-7">
            <div className="container">

            <a className="navbar-brand" href="/">
                <img src="images/LOGO.png" alt="Logo" width={220} height={70} />
              </a>
              <div className="login-form modheight">
                <div className="login-form-head ">
                  <h2 ><span className='h2txt'>Welcome to</span> <span>DigitalRaiz</span></h2>
                  <p>Fill out the form to get started..</p>
                </div>
                <form onSubmit={usersSubmit}>
                  <div className="form-group">
                    <label className="form-label"
                      htmlFor="signinEmail"
                    >Full Name</label>
                    <div className="input-group">
                      <div className="input-icon">
                        <span className="fa fa-user-o" />
                      </div>
                      <input type="text" pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$" className="form-control" name="name" onChange={(e) => { handlechange(e); }} id="signinEmail" placeholder="Full Name" aria-label="Email address" required />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="signinPassword">
                      Mobile
                    </label>
                    <div className="input-group">
                      <div className="input-icon">
                        <span className="fa fa-mobile" />
                      </div>
                      <input type="text" minlength="0" maxlength="10" pattern="\d{10}" className="form-control" name="phone" id="signinPassword" onChange={(e) => { handlechange(e); }} placeholder="Mobile No..." aria-label="Password" required />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="signinPassword2"> Email Id</label>
                    <div className="input-group">
                      <div className="input-icon">
                        <span className="fa fa-envelope-o" />
                      </div>
                      <input type="email" pattern="(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)" className="form-control" name="email" id="signinPassword2" onChange={(e) => { handlechange(e); }} placeholder="Email Id" aria-label="Password" required />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="signinPassword2">Services</label>
                    <div className="input-group">
                      <div className="input-icon">
                        <span className="fa fa-globe" />
                      </div>
                      <select className="form-control" name="service" id="signinPassword2" placeholder="Email Id" onChange={(e) => { handlechange(e); }} aria-label="Password" required >
                        <option value="">Select</option>
                        <option
                          className="form-control"
                          value="Mobile Application"
                        >
                          Mobile Application
                        </option>
                        <option
                          className="form-control"
                          value="Website Design & Development"
                        >
                          Website Design & Development
                        </option>
                        <option
                          className="form-control"
                          cvalue="Digital Marketing"
                        >
                          Digital Marketing
                        </option>
                        <option className="form-control" cvalue="Others">Others</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                  </div>
                  <div className="form-group">
                    <button className="btn theme-btn btn-block" type='submit'>Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

      </Modal> */}
      <Toaster />
    </div>
  );
}

export default Header;
